import actionNames from '../modules/actionNames';

function baseActionsFor(resourceName, singularName) {
  const actions = actionNames(resourceName);

  return {
    clear() {
      return dispatch => {
        dispatch({
          entity: singularName,
          type: 'ENTITY_CLEAR'
        });
        return Promise.resolve();
      };
    },

    fetchStart(data) {
      return {
        data,
        type: actions.fetchStart,
      };
    },

    fetchSuccess(payload) {
      return {
        payload,
        type: actions.fetchSuccess
      };
    },

    fetchError(error, data) {
      return {
        data,
        error,
        type: actions.fetchError,
      };
    },

    fetchOneStart(data) {
      return {
        data,
        type: actions.fetchOneStart,
      };
    },

    fetchOneSuccess(payload) {
      return {
        payload,
        single: true,
        type: actions.fetchOneSuccess,
      };
    },

    fetchOneError(error, data) {
      return {
        data,
        error,
        type: actions.fetchOneError,
      };
    },

    createStart(data) {
      return {
        data,
        type: actions.createStart,
      };
    },

    createSuccess(payload) {
      return {
        payload,
        single: true,
        type: actions.createSuccess,
      };
    },

    createError(error, data) {
      return {
        data,
        error,
        type: actions.createError,
      };
    },

    updateStart(data) {
      return {
        data,
        type: actions.updateStart,
      };
    },

    updateSuccess(payload) {
      return {
        payload,
        single: true,
        type: actions.updateSuccess,
      };
    },

    updateError(error, data) {
      return {
        data,
        error,
        type: actions.updateError,
      };
    },

    deleteStart(data) {
      return {
        data,
        type: actions.deleteStart,
      };
    },

    deleteSuccess(id) {
      return {
        id,
        type: actions.deleteSuccess,
      };
    },

    deleteError(error, data) {
      return {
        data,
        error,
        type: actions.deleteError,
      };
    }
  };
}

export default baseActionsFor;
