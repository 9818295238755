import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import styles from './Header.module.postcss';

class HeaderSubNav extends PureComponent {
  render() {
    const { children, active, onClose } = this.props;

    return (
      <nav className={styles.GlobalHeader_subNav + ` ${active ? styles.active : ""}`} style={{ display: 'none' }}>
        <div>
          <button
            type="button"
            className={styles.SubNav_close}
            onClick={onClose}
          >
            <SVG src={"/static/images/icons/material-ui-chevron-left.svg"} alt="Back"/>
          </button>
        </div>
        <div className={styles.SubNav_content}>
          {children}
        </div>
      </nav>
    );
  }
}

HeaderSubNav.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
};

export default HeaderSubNav;
