const API = {
  get(url) {
    return $.ajax({
      method: 'GET',
      url
    })
      .then((data) =>
        data
      );
  },

  post(url, props) {
    return $.ajax({
      method: 'POST',
      url,
      data: props
    })
      .then((data) =>
        data
      );
  },

  put(url, props) {
    return $.ajax({
      method: 'PUT',
      url,
      data: props
    })
      .then((data) =>
        data
      );
  },

  delete(url) {
    return $.ajax({
      method: 'DELETE',
      url
    })
      .then((data) =>
        data
      );
  }
};

export default API;
