import React from 'react';
import maxSize from 'popper-max-size-modifier';
import HeadlessTippy from '@tippyjs/react/headless';

import NearMeContent from './NearMeContent';

import styles from './Header.module.postcss';

const applyMaxSize = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['maxSize'],
  fn({ state }) {
    const { width, height } = state.modifiersData.maxSize;
    state.styles.popper = {
      ...state.styles.popper,
      maxWidth: `${width}px`,
      maxHeight: `${height}px`
    };
  }
};

const NearMeDropdown = (props) => {
  return (
    <React.Fragment>
      <HeadlessTippy
        animation='scale-subtle'
        interactive={true}
        placement='bottom-start'
        onHide={(instance) => {
          requestAnimationFrame(instance.unmount);
        }}
        popperOptions={{
          modifiers: [
            maxSize,
            applyMaxSize,
          ],
        }}
        render={attrs => (
          <div className={styles.NearMePopover} tabIndex="-1" {...attrs}>
            <NearMeContent cityConfig={props.cityConfig} />
          </div>
        )}
      >
        <button type="button" className={styles.GlobalHeader_navLink + " " + styles.NearMeTrigger + " u-ml05 u-noMobileInlineBlock"}>
          Near Me
        </button>
      </HeadlessTippy>
      <button
        type="button"
        className={styles.GlobalHeader_navLink + " u-mobileOnly"}
        onClick={props.onMobileClick}
      >
        <img
          className={styles.headerMenuIcon}
          src='/static/images/admin/navigation/pods.svg'
          width='64'
          height='64'
          alt='Pods'
        />
        Near Me
      </button>
    </React.Fragment>
  );
};

export default React.memo(NearMeDropdown);
