// NPM Requirements
import { normalize } from 'normalizr';
import _ from 'lodash';
import param from 'jquery-param';

// Misc
import API from '../modules/api';
import { displayError } from './errorActions';

function asyncActionsFor(options) {
  const { baseActions, resourceName, listName,
          resourceSchema, listSchema } = options;

  let apiEndpoint = options.apiEndpoint;

  if (!apiEndpoint) {
    apiEndpoint = listName;
  }

  return {
    fetchOne(id, params = null, skipLoader = false) {
      return dispatch => {
        if (!skipLoader) {
          dispatch(baseActions.fetchOneStart());
        }
        const promise = API.get(`/api/${apiEndpoint}/${id}?${param(params)}`);

        promise.then((response) => {
          const records = normalize(response, resourceSchema);
          dispatch(baseActions.fetchOneSuccess(records));
        });

        promise.catch((response) => {
          dispatch(baseActions.fetchOneError(response));
          if (response.status === 404) {
            dispatch(displayError(404, response.statusText));
          } else {
            dispatch(displayError(500, response.statusText, true));
          }
        });

        return promise;
      };
    },

    fetchOneIfNeeded(id) {
      return (dispatch, getState) => {
        const state = getState();
        if (!state.entities[resourceName] || !state.entities[resourceName][id]) {
          return dispatch(this.fetchOne(id));
        }
      };
    },

    fetch(params, skipLoader = false) {
      return dispatch => {
        if (!skipLoader) {
          dispatch(baseActions.fetchStart());
        }
        const promise = API.get(`/api/${apiEndpoint}?${param(params)}`);
        promise.then((response) => {
          const records = normalize(response[_.snakeCase(listName)], listSchema);
          if (response.pagination) {
            records.pagination = {};
            records.pagination[resourceName] = response.pagination;
          }
          dispatch(baseActions.fetchSuccess(records));
        });

        promise.catch((response) => {
          dispatch(baseActions.fetchError(response));
          if (response.status === 404) {
            dispatch(displayError(404, response.statusText));
          } else {
            dispatch(displayError(500, response.statusText, true));
          }
        });

        return promise;
      };
    },

    fetchIfNeeded(params) {
      return (dispatch, getState) => {
        const state = getState();
        if (!state[listName] || state[listName].length === 0 || _.isEmpty(state[listName])) {
          return dispatch(this.fetch(params));
        }
      };
    },

    update(record) {
      return dispatch => {
        dispatch(baseActions.updateStart());
        const promise = API.put(`/api/${apiEndpoint}/${record.id}`,
                                { [resourceName]: record });

        promise.then((response) => {
          const records = normalize(response, resourceSchema);
          dispatch(baseActions.updateSuccess(records));
        });

        promise.catch((response) => {
          dispatch(baseActions.updateError(response));
          if (response.status === 404) {
            dispatch(displayError(404, response.statusText, true));
          } else {
            dispatch(displayError(500, response.statusText, true));
          }
        });

        return promise;
      };
    },

    delete(id) {
      return dispatch => {
        dispatch(baseActions.deleteStart());
        const promise = API.delete(`/api/${apiEndpoint}/${id}`);

        promise.then(() => {
          dispatch(baseActions.deleteSuccess(id));
        });

        promise.catch((response) => {
          dispatch(baseActions.deleteError(response));
          if (response.status === 404) {
            dispatch(displayError(404, response.statusText, true));
          } else {
            dispatch(displayError(500, response.statusText, true));
          }
        });

        return promise;
      };
    },

    create(record) {
      return dispatch => {
        dispatch(baseActions.createStart());
        const promise = API.post(`/api/${apiEndpoint}`,
                                { [resourceName]: record });

        promise.then((response) => {
          const records = normalize(response, resourceSchema);
          dispatch(baseActions.createSuccess(records));
        });

        promise.catch((response) => {
          dispatch(baseActions.createError(response));
          if (response.status === 404) {
            dispatch(displayError(404, response.statusText, true));
          } else {
            dispatch(displayError(500, response.statusText, true));
          }
        });

        return promise;
      };
    }
  };
}

export default asyncActionsFor;
