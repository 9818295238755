import { Loader } from '@googlemaps/js-api-loader';
import IsoMorphUtils from './isoMorphUtils';

export const loaderOptions = {
  apiKey: IsoMorphUtils.railsContext.gmKey,
  version: 'weekly',
  libraries: ['places', 'geocoding', 'core']
}

const loader = new Loader(loaderOptions);

const GoogleMapsUtils = {

  importAll() {
    return loader.load();
  },

  // Import specific libraries
  importLibraries(libraries = []) {
    let promises = [];

    libraries.forEach((library) => {
      promises.push(loader.importLibrary(library));
    })

    return Promise.all(promises);
  },

};

export default GoogleMapsUtils;
