import _ from 'lodash';
import queryString from 'query-string';

const RouteUtils = {
  getQueryParams(location) {
    return queryString.parse(location.search);
  },

  updateQueryParam(history, location, newParams, pathname) {
    const currentParams = RouteUtils.getQueryParams(location);

    let params = _.assign({}, currentParams, newParams);
    params = _.omitBy(params, _.isNil);
    params = _.omitBy(params, (p) => { return !p; });

    history.push({
      pathname: pathname || history.location.pathname,
      search: queryString.stringify(params)
    });
  },
};

export default RouteUtils;
